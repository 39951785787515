import { render, staticRenderFns } from "./UserDataComponent.vue?vue&type=template&id=ce108e64&scoped=true&"
import script from "./UserDataComponent.vue?vue&type=script&lang=js&"
export * from "./UserDataComponent.vue?vue&type=script&lang=js&"
import style0 from "./UserDataComponent.vue?vue&type=style&index=0&id=ce108e64&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce108e64",
  null
  
)

export default component.exports