<template>
  <v-data-table
    height="250px"
    :headers="headersStorageDevice"
    :items="locations"
    v-model="selected"
    :search="searchStorage"
    :options.sync="options"
    show-select
    :server-items-length="total"
    :loading="loading"
    @toggle-select-all="setLocations()"
    @item-selected="setLocations()"
    :no-data-text="$t('general.ui.not_data_found')"
    :loading-text="$t('general.notification.loadData')"
    :single-select="disabled"
    :item-key="itemKey"
    :items-per-page.sync="itemPerPage"
    :footer-props="{
      ...rowsPerPageItemsOptions,
      itemsPerPageText: $t('general.table.itemsPerPageText'),
      pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
    }"
  >
    <template v-slot:item.data-table-select="{ item, isSelected, select }">
      <v-simple-checkbox
        :value="isSelected"
        :readonly="item.disabled"
        :disabled="disabled"
        @input="select($event)"
      ></v-simple-checkbox>
    </template>
    <template v-slot:top>
      <v-text-field
        class="mb-2"
        onkeypress="return (event.charCode != 34)"
        v-on:keyup.86="replace"
        v-on:keyup.ctrl.86="replace"
        dense
        filled
        rounded
        color="secondary"
        v-model="searchStorage"
        :clearable="true"
        append-icon="mdi-magnify"
        :label="$t('general.titles.filter')"
        single-line
        hide-details
        @keypress.enter="searchForText"
        @click:clear="searchAll"
        @click:append="searchForText"
      />
    </template>
  </v-data-table>
</template>

<script>
  import i18n from "@/plugins/i18n"
  import { mapActions, mapGetters } from "vuex"
  import _ from "lodash"

  const PROFILE = JSON.parse(localStorage.getItem("profile"))

  export default {
    name: "StoragesTable",

    props: {
      editedItem: {
        type: Object,
        require: true,
      },
      itemKey: {
        type: String,
        require: true,
      },
      grant: {
        type: Boolean,
        require: false,
        default: false
      },
      revoke: {
        type: Boolean,
        require: false,
        default: false
      },
      disabled: {
        type: Boolean,
        require: false,
        default: false
      }
    },

    data: () => ({
      searchStorage: null,
      defaultFilters: {
        companyId: PROFILE.company_id,
        userId: null,
        search: "",
        checkPermissions: false,
        pageableDTO: {
          page: 0,
          size: 10,
          sortBy: "id",
          direction: "DESC",
        },
      },
      options: {},
      loading: false,
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [10, 20, 50, 100, 200],
      },
      itemPerPage: 10,
      selected: [],
      locations: [],
      total: 0
    }),

    watch: {
      options: {
        async handler() {
          await this.searchText()
        },
        deep: true,
      },
    },

    computed: {
      ...mapGetters({
        storages: "storage/storagesByUserAccess",
        totalFromServer: "storage/totalFromServer"
      }),
      headersStorageDevice: () => [
        {
          text: i18n.t("storage.fields.name"),
          value: "control_label",
          sortable: false},
        {
          text: i18n.t("storage.fields.storage_type"),
          value: "storage_device_type_name",
          sortable: false
        },
        {
          text: i18n.t("storage.fields.location"),
          value: "primaryLocation.name",
          sortable: false
        },
      ],
    },

    created() {
      this.updateFilters()
    },

    async mounted() {
      this.$root.$on("load:storages", async item => {
        this.defaultFilters.userId = item.id
        this.defaultFilters.pageableDTO.size = this.itemPerPage

        this.loading = true;

        await this.fetchAccessStoragesByUser([{
          ...this.defaultFilters
        }, this.$toast]).then(() => {
          this.$set(this, 'locations', this.storages)
          this.total = this.totalFromServer
        }).finally(() => {
          this.loading = false;
        })
      })


      this.$root.$on("clear:locations", () => {
        this.$set(this, 'selected', []);
        this.$set(this, 'locations', []);
      });
    },

    methods: {
      ...mapActions({
        fetchAccessStoragesByUser: "storage/fetchAccessStoragesByUser",
      }),
      setLocations() {
        setTimeout(() => {
          this.$emit('update:selected', this.selected)
        }, 0)
      },
      updateFilters() {
        if (this.grant)
          this.defaultFilters = {
            userId: this.editedItem.id,
            pageableDTO: {
              page: 0,
              size: 10,
              sortBy: "id",
              direction: "DESC"
            },
            companyId: PROFILE.company_id,
            moduleAdmin: true,
            hasGrantAccess: true
          }

        if (this.revoke)
          this.defaultFilters = {
            userId: this.editedItem.id,
            pageableDTO: {
              page: 0,
              size: 10,
              sortBy: "id",
              direction: "DESC"
            },
            companyId: PROFILE.company_id,
            moduleAdmin: true,
            hasGrantAccess: false
          }
      },
      async searchAll() {
        this.loading = true

        await this.fetchAccessStoragesByUser([{
          ...this.defaultFilters
        }, this.$toast]).then(() => {
          this.$set(this, 'locations', this.storages)
          this.total = this.totalFromServer
        }).finally(
          () => {
            this.loading = false
          }
        )
      },
      async searchForText() {
        this.options.page = 1
        await this.searchText()
      },
      async searchText() {
        this.loading = true

        let filters = Object.assign({}, this.defaultFilters)

        const direction =
          this.options.sortDesc.length === 0 || this.options.sortDesc[0]
            ? "asc"
            : "desc"
        const sort =
          this.options.sortBy.length === 0
            ? "id"
            : _.filter(this.headers, { value: this.options.sortBy[0] })[0].value
        filters.pageableDTO = {
          page: this.options.page - 1,
          size: this.options.itemsPerPage,
          sortBy: sort,
          direction: direction,
        }

        if (this.searchStorage && this.searchStorage !== "")
          filters.stringFilter = this.searchStorage

        await this.fetchAccessStoragesByUser([{
          ...filters
        }, this.$toast]).then(() => {
          this.$set(this, 'locations', this.storages)
          this.total = this.totalFromServer
        }).finally(
          () => {
            this.loading = false
          }
        )
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
    },

    destroyed() {
      this.$set(this, 'selected', [])
      this.$root.$off("load:storages");
      this.$root.$off("clear:locations");
    },
  }
</script>

<style scoped></style>
