<template>
  <v-dialog persistent :value="dialog" :keep-alive="false" v-model="dialog" max-width="860px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        tile
        :elevation="0"
        color="#d8d8d896"
        style="border-radius: 1.5em"
        v-if="check([{ domain: 'PersonInCharge', permisions: ['Write'] }])"
        @click="$emit('dialog:action', true)"
      >
        <v-icon small color="#6D6D6D" left>mdi-plus</v-icon>
        {{ $t("general.buttons.new") }}
      </v-btn>
    </template>
    <v-card :light="true" color="white">
      <v-card-title>
        <span class="mx-2 headline">{{ formTitle }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row class="mb-4">
            <v-col cols="6">
              <h2 class="secondary--text py-2">{{ $t('general.titles.userInfo') }}</h2>
            </v-col>
            <v-col cols="6">
              <h2 class="secondary--text py-2">
                {{ $t('general.titles.locationInfo') }}
              </h2>
            </v-col>
          </v-row>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  onkeypress="return (event.charCode != 34)"
                  v-on:keyup.86="replaceName"
                  v-on:keyup.ctrl.86="replaceName"
                  color="secondary"
                  v-model="editedItem.name"
                  required
                  class="required"
                  :rules="requireAndMaxRules(max)"
                  :counter="max"
                  persistent-hint
                  small
                  flat
                  outlined
                  dense
                >
                  <template v-slot:label>
                    {{ $t("authority.fields.name") }}
                    <strong class="red--text">*</strong>
                  </template>
                </v-text-field>

                <v-text-field
                  onkeypress="return (event.charCode != 34)"
                  v-on:keyup.86="replaceEmail"
                  v-on:keyup.ctrl.86="replaceEmail"
                  color="secondary"
                  v-model="editedItem.email"
                  required
                  class="required"
                  :rules="requireRules.concat(emailRules)"
                  persistent-hint
                  small
                  flat
                  outlined
                  dense
                >
                  <template v-slot:label>
                    {{ $t("authority.fields.email") }}
                    <strong class="red--text">*</strong>
                  </template>
                </v-text-field>

                <v-select
                  deletable-chips
                  v-model="editedItem.roles"
                  color="secondary"
                  item-color="secondary"
                  :items="orderDatas(roles, 'name')"
                  small-chips
                  :label="$t('authority.fields.roles')"
                  item-text="name"
                  item-value="id"
                  return-object
                  multiple
                  persistent-hint
                  small
                  flat
                  outlined
                  dense
                >
                </v-select>

                <v-text-field
                  onkeypress="return (event.charCode != 34)"
                  v-on:keyup.86="replaceDocumentKey"
                  v-on:keyup.ctrl.86="replaceDocumentKey"
                  color="secondary"
                  v-model="editedItem.document_key"
                  required
                  class="required"
                  :rules="requireRules"
                  persistent-hint
                  small
                  flat
                  outlined
                  dense
                >
                  <template v-slot:label>
                    {{ $t("authority.fields.identificationType") }}
                    <strong class="red--text">*</strong>
                  </template>
                </v-text-field>

                <v-text-field
                  onkeypress="return (event.charCode != 34)"
                  v-on:keyup.86="replaceDocumentPrivate"
                  v-on:keyup.ctrl.86="replaceDocumentPrivate"
                  color="secondary"
                  v-model="editedItem.document_private"
                  required
                  class="required"
                  :rules="requireRules"
                  persistent-hint
                  small
                  flat
                  outlined
                  dense
                >
                  <template v-slot:label>
                    {{ $t("authority.fields.identificationValue") }}
                    <strong class="red--text">*</strong>
                  </template>
                </v-text-field>

                <v-select
                  id="locale"
                  :items="langs"
                  v-model="editedItem.language"
                  :label="$t('authority.fields.language')"
                  max-height="auto"
                  text
                  :dense="false"
                  color="#cccccc"
                  :flat="false"
                  background-color="white"
                  :dark="false"
                  item-color="secondary"
                  :hide-details="true"
                  append-icon="mdi-chevron-down"
                  persistent-hint
                  small
                  flat
                  outlined
                  dense
                  :rules="selectRule"
                >
                  <template v-slot:label>
                    {{ $t('authority.fields.language') }}
                    <strong class="red--text">*</strong>
                  </template>
                  <template slot="selection" slot-scope="data">
                    <div id="languajeSelected">
                      <v-avatar tile :height="14" :width="22" :min-width="22">
                        <v-img
                          :src="require('@/assets/flag/' + data.item + '.png')"
                        />
                      </v-avatar>
                    </div>
                  </template>
                  <template slot="item" slot-scope="data">
                    <template>
                      <div class="languajeName">
                        <v-avatar tile :height="14" :width="22" :min-width="22">
                          <v-img
                            :src="
                              require('@/assets/flag/' + data.item + '.png')
                            "
                          />
                        </v-avatar>
                        {{ data.item.split("-")[1].toUpperCase() }}
                      </div>
                    </template>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="editedItem.primaryLocationOwner"
                  :clearable="true"
                  :items="locations"
                  :label="$t('documents.fields_add.locations')"
                  :no-data-text="$t('general.fields.noDataAvailable')"
                  class="required"
                  item-color="secondary"
                  item-value="locationId"
                  item-text="name"
                  small-chips
                  persistent-hint
                  small
                  flat
                  outlined
                  dense
                  color="secondary"
                  clearable
                  :loading="loadings.origin"
                  @click:clear="clearLocations"
                  @change="
                                fetchStorages({
                                primaryLocationIds: editedItem.primaryLocationOwner,
                                isActive: true
                              })"
                  @keypress.enter="
                                fetchLocations({
                                stringFilter: $event.target.value,
                                isActive: true
                              })"
                >
                  <template v-slot:prepend-item>
                    <v-alert
                      color="secondary"
                      type="info"
                      dense
                      border="right"
                      text
                      tile
                      class="ma-0"
                    >
                      <template #append>
                        <code><v-icon color="secondary" v-text="'mdi-keyboard-return'"/></code>
                      </template>
                      <template #prepend>
                        <v-icon class="mr-2" color="secondary" v-text="'mdi-information-slab-circle-outline'"/>
                      </template>
                      {{ $t('general.fields.startTyping') }}
                    </v-alert>
                  </template>
                </v-autocomplete>

                <v-autocomplete
                  :no-data-text="$t('general.fields.noDataAvailable')"
                  persistent-hint
                  small
                  flat
                  outlined
                  dense
                  ref="storageDeviceFilters"
                  :label="$t('reports.fields.storage_device')"
                  clearable
                  item-color="secondary"
                  color="secondary"
                  item-text="control_label"
                  :disabled="!editedItem.primaryLocationOwner"
                  return-object
                  multiple
                  :loading="loadings.destination"
                  :items="storages"
                  v-model="editedItem.locationsOwner"

                  @mouseup="
                                checkState(
                                  'localization',
                                  $refs.storageDeviceFilters.filteredItems,
                                  editedItem.locationsOwner
                                )
                              "
                  @keypress.enter="
                                fetchStorages({
                                primaryLocationIds: editedItem.primaryLocationOwner,
                                isActive: true,
                                stringFilter: $event.target.value
                              })"
                  small-chips
                >
                  <template v-slot:prepend-item>
                    <v-alert
                      color="secondary"
                      type="info"
                      dense
                      border="right"
                      text
                      tile
                      class="ma-0"
                    >
                      <template #append>
                        <code><v-icon color="secondary" v-text="'mdi-keyboard-return'"/></code>
                      </template>
                      <template #prepend>
                        <v-icon class="mr-2" color="secondary" v-text="'mdi-information-slab-circle-outline'"/>
                      </template>
                      {{ $t('general.fields.startTyping') }}
                    </v-alert>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip small v-if="index === 0">
                      <span>{{ item.control_label | truncate(20) }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption"
                      >(+{{ editedItem.locationsOwner.length - 1 }}
                      {{ $t("general.titles.others") }})</span
                    >
                  </template>
                  <template v-slot:prepend-item>
                    <v-checkbox
                      v-model="checkboxState.localization"
                      color="secondary"
                      class="pt-2 pb-2 mt-0 pl-4"
                      :hide-details="true"
                      @change="
                        $set(editedItem, 'locationsOwner', selectAll(
                                      'localization',
                                      editedItem.locationsOwner,
                                      $refs.storageDeviceFilters.filteredItems,
                                      checkboxState.localization
                                    ))
                                  "
                    >
                      <template v-slot:label>
                        <v-btn small color="secondary" x-small text>
                          {{ $t("general.titles.selectAll") }}
                        </v-btn>
                      </template>
                    </v-checkbox>
                    <v-divider class="mb-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small tile outlined color="gray" @click="close">
          {{ $t("general.buttons.cancel") }}
        </v-btn>
        <v-btn
          small
          tile
          outlined
          color="secondary"
          :disabled="!valid"
          @click="save"
        >
          {{ $t("general.buttons.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import _ from "lodash"
  import i18n from "@/plugins/i18n"
  import { mapActions, mapGetters } from "vuex"
  import LanguageService from "@/services/LanguajeService"
  import OrderListService from "@/services/OrderListService"
  import PermissionService from "@/services/PermisionService"
  import AutocompleteLocations from "@/components/AutocompleteLocations.vue"

  const PROFILE = JSON.parse(localStorage.getItem("profile"))

  export default {
    name: "UserDataComponent",
    components: { AutocompleteLocations },

    props: {
      dialog: {
        type: Boolean,
        require: true,
        default: false,
      },
      langs: {
        type: Array,
        require: true,
      },
      editedIndex: {
        type: Number,
        require: false,
        default: -1,
      },
    },

    data: () => ({
      valid: false,
      max: 40,
      editedItem: {
        name: "",
        company_id: null,
        password: "",
        email: "",
        document_key: "",
        document_private: "",
        revoke: [],
        grant: [],
        language: "",
        roles: [],
        process: [],
        locationsOwner: [],
        primaryLocationOwner: null
      },
      loadings: {
        origin: false,
        destination: false,
      },
      checkboxState: {
        localization: false,
      },
    }),

    async mounted() {
      this.$root.$on("load:authority", async editedIndex => {
        this.editedItem = Object.assign({}, this.authority);

        this.$set(this.editedItem, 'language', LanguageService.getKeyByLenguajeName(
          this.editedItem.language
        ));

        if (editedIndex !== -1) {
          if (this.editedItem.primaryLocationOwner) {
            this.$set(this.loadings, "origin", true)
            await this.fetchPrimaryLocations([
              {
                ids: [this.editedItem.primaryLocationOwner.locationId],
                isActive: true,
              },this.$toast,
            ]).finally(() => {
              this.$set(this.editedItem, 'primaryLocationOwner', this.editedItem.primaryLocationOwner)
              this.$set(this.loadings, "origin", false)
            })

            this.$set(this.loadings, "destination", true)
            await this.fetchStorageLocations([
              {
                primaryLocationIds: [this.editedItem.primaryLocationOwner.locationId],
                onlyCurrentVersion:true,
                isActive: true
              },this.$toast])
              .finally(() => {
                this.$set(this.editedItem, 'locationsOwner', this.editedItem.locationsOwner)
                this.$set(this.loadings, "destination", false)
              })
          }
        } else {
          await this.fetchPrimaryLocations([
            {
              stringFilter: "",
              isActive: true,
            },
            this.$toast,
          ]).finally(() => {
            this.$set(this.loadings, "origin", false)
          })
        }
      })
    },

    computed: {
      authority: {
        get() {
          return this.$store.getters["authority/authority"]
        },
        set(value) {
          this.$store.commit("authority/SET_AUTHORITY", value)
        },
      },
      ...mapGetters({
        storages: "storage/storagesList",
        locations: "locations/locationsList",
        roles: "authority/roles",
        requireRules: "general/requireRules",
        emailRules: "general/emailRules",
        selectRule: "general/selectRule",
        requireAndMaxRules: "general/requireAndMaxRules",
      }),
      formTitle() {
        return (
          (this.editedIndex === -1
            ? i18n.t("general.titles.new")
            : i18n.t("general.titles.edit")) +
          " " +
          i18n.t("authority.titles.new_authority")
        )
      },
    },

    methods: {
      ...mapActions({
        fetchListAuthority: "authority/fetchListAuthority",
        saveAuthority: "authority/saveAuthority",
        fetchPrimaryLocations: "locations/fetchFilteredLocations",
        fetchStorageLocations: "storage/fetchFilteredStorages",
      }),
      selectAll(type, length, value, checkbox) {
        if (checkbox === false) {
          this.$nextTick(function () {
            switch (type) {
              case 'localization':
                this.checkboxState.localization = false
                break
            }
          })
          return []
        }
        let aux = []
        if (length != null) {
          _.forEach(length, (object) => {
            if (!_.find(value, (o) => {
              return o.id === object.id
            }))
              value.push(object)
          })

          aux = value
        } else {
          aux = value
        }
        return aux
      },
      checkState(prefix, total, values) {
        if (values.length === total.length) {
          switch (prefix) {
            case 'localization':
              this.checkboxState.localization = true
              break
          }
        } else {
          switch (prefix) {
            case 'localization':
              this.checkboxState.localization = false
              break
          }
        }
      },
      async fetchLocations(filters) {
        if (filters) {
          this.$set(this.editedItem, "primaryLocationOwner", null)

          this.$set(this.loadings, "origin", true)

          await this.fetchPrimaryLocations([
            filters,
            this.$toast,
          ]).finally(() => {
            this.$set(this.loadings, "origin", false)
          })
        }
      },
      async fetchStorages(filters) {
        if (filters && filters.primaryLocationIds) {
          this.$set(this.editedItem, 'locationsOwner', [])
          this.$set(this.loadings, 'destination', true)

          if (typeof filters.primaryLocationIds === 'object')
            filters.primaryLocationIds = [filters.primaryLocationIds.id]
          else
            filters.primaryLocationIds = [filters.primaryLocationIds]

          for (let property in filters) {
            if (filters[property] === null) {
              delete filters[property]
            }
          }

          await this.fetchStorageLocations([
            filters,
            this.$toast,
          ]).finally(() => {
            this.$set(this.loadings, 'destination', false)
          })
        }
      },
      clearLocations() {
        this.$set(this.editedItem, 'primaryLocationOwner', [])
        this.$set(this.editedItem, 'locationsOwner', [])
        this.$refs.form.resetValidation()
      },
      orderDatas(list, order) {
        return OrderListService.orderListByUppercase(list, order)
      },
      save() {
        let item = Object.assign({}, this.editedItem)

        item.company_id = PROFILE.company_id
        item.language = LanguageService.getLenguajeNameByKey(item.language)
        item.password = ""

        item.locationsOwner = _.map(item.locationsOwner, l => {
          return l.storageDeviceId
        })

        this.saveAuthority([item, this.editedIndex, this.$toast]).finally(
          () => {
            this.fetchListAuthority([PROFILE, this.$toast])
            this.close()
          }
        )
      },
      async close() {
        await this.fetchPrimaryLocations([{
          stringFilter: "",
          isActive: true,
        }, this.$toast])

        setTimeout(async () => {
          await this.$emit("update:item")
          this.editedItem = Object.assign({}, this.authority);
          this.$refs.form.reset()
        }, 0)
      },
      check(permisions) {
        return PermissionService.check(permisions)
      },
      replaceDocumentPrivate(event) {
        this.editedItem.document_private = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
      replaceDocumentKey(event) {
        this.editedItem.document_key = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
      replaceName(event) {
        this.editedItem.name = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
      replaceEmail(event) {
        this.editedItem.email = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
    },

    destroyed() {
      this.$root.$off("load:authority")
    },
  }
</script>

<style scoped>
  .bordered {
    border: 1px solid green;
    border-radius: 0.2em;
  }
</style>
