import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDataTable,{attrs:{"height":"250px","headers":_vm.headersStorageDevice,"items":_vm.locations,"search":_vm.searchStorage,"options":_vm.options,"show-select":"","server-items-length":_vm.total,"loading":_vm.loading,"no-data-text":_vm.$t('general.ui.not_data_found'),"loading-text":_vm.$t('general.notification.loadData'),"single-select":_vm.disabled,"item-key":_vm.itemKey,"items-per-page":_vm.itemPerPage,"footer-props":{
    ..._vm.rowsPerPageItemsOptions,
    itemsPerPageText: _vm.$t('general.table.itemsPerPageText'),
    pageText: '{0}-{1} ' + _vm.$t('general.table.pageText') + ' {2}',
  }},on:{"update:options":function($event){_vm.options=$event},"toggle-select-all":function($event){return _vm.setLocations()},"item-selected":function($event){return _vm.setLocations()},"update:itemsPerPage":function($event){_vm.itemPerPage=$event},"update:items-per-page":function($event){_vm.itemPerPage=$event}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function({ item, isSelected, select }){return [_c(VSimpleCheckbox,{attrs:{"value":isSelected,"readonly":item.disabled,"disabled":_vm.disabled},on:{"input":function($event){return select($event)}}})]}},{key:"top",fn:function(){return [_c(VTextField,{staticClass:"mb-2",attrs:{"onkeypress":"return (event.charCode != 34)","dense":"","filled":"","rounded":"","color":"secondary","clearable":true,"append-icon":"mdi-magnify","label":_vm.$t('general.titles.filter'),"single-line":"","hide-details":""},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==86)return null;return _vm.replace.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==86)return null;if(!$event.ctrlKey)return null;return _vm.replace.apply(null, arguments)}],"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchForText.apply(null, arguments)},"click:clear":_vm.searchAll,"click:append":_vm.searchForText},model:{value:(_vm.searchStorage),callback:function ($$v) {_vm.searchStorage=$$v},expression:"searchStorage"}})]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})
}
var staticRenderFns = []

export { render, staticRenderFns }